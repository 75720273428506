/*! =======================================
このCSSファイルはSassから生成されていますので、
編集しないようにご注意ください。
========================================= */

/*!
メインの設定(全体)
------------------------------- */
*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-size: $fontsize-base;
    width: 100%;
    font-family: $font-base;
    font-weight: 300;
    line-height: $line_height;
    color: $color-font;

    @include media(tb) {
        line-height: $line_height_sp;
    }
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $color-font;
    display: block;
    transition: all 0.3s ease;
}

a:hover {
    opacity: 0.8;
}

img {
    max-width: 100%;
    width: 100%;
    vertical-align: bottom;
}

.infopage_area img {
    width: 100%;
    height: auto;
}

p {
    line-height: $line_height;

    @include media(tb) {
        line-height: $line_height_sp;
    }
}


.pc-none {
    display: none;

    @include media(tb) {
        display: block;
    }
}

@include media(tb) {
    .sp-none {
        display: none;
    }
}

.wrap {
    max-width: $width-base;
    width: 80%;
    margin: 0 auto;

    @include media(tb) {
        width: 100%;
    }
}

.box_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.flex {
    display: flex;
}

.btn_50 {
    line-height: 48px;
    text-align: center;
    color: inherit;

    &:hover {
        opacity: 1;
    }
}

.btn_70 {
    line-height: 69px;
    text-align: center;
    color: inherit;
}

.box_ttl {
    font-family: $font-enmain;
    font-size: 26px;
    color: $color-main;
    font-weight: 600;
    letter-spacing: 0.3em;
}

/*======================
    MENUの設定
========================*/
#menu {
    background-color: $color-bg;
}

.menu {
    padding-bottom: 138px;

    &_title {
        text-align: center;
        padding-top: 100px;
    }

    &_container {

        &_img,
        &_itemlist {
            width: 50%;
            margin-top: 75px;
        }

        &_img {
            padding-right: 20px;

            img {
                width: 338px;
                height: auto;
            }
        }
    }
}

@include media(tb) {
    .menu {
        padding-bottom: 65px;

        &_title {
            padding-top: 65px;
        }

        &_container {
            flex-direction: column;
            align-items: center;

            &_img,
            &_itemlist {
                width: 100%;
                margin-top: 60px;
            }

            &_img {
                padding-right: 0;
                text-align: center;
            }
        }

    }
}

/*======================
    WEB予約の設定
========================*/
.web-reservation {
    background-color: $color-main;
    height: 180px;
    padding: 55px 20px;

    a {
        max-width: 388px;
        height: 70px;
        font-size: $fontsize-base;
        border: 1px solid #fff;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        letter-spacing: 0.15em;

        &:hover {
            opacity: 1;
            background: #fff;
            color: $color-main;
        }
    }
}

/*======================
        staffの設定
========================*/

.staff {
    &_title {
        display: block;
        padding-top: 100px;
        text-align: center;
    }

    &_container {
        justify-content: center;

        &_item {
            margin-top: 84px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &_img {
                margin: 0 auto;
                max-width: 260px;
            }
        }
    }

    &_text {
        margin-top: 67px;
        padding-bottom: 109px;
        font-size: $fontsize-base;
        line-height: 32px;
        letter-spacing: 0.1em;
    }
}

.staff_container_item {
    &_container {
        font-family: $font-enmain;
        text-align: center;

        &_about {
            font-size: $fontsize-base;
            margin-top: 32px;
            letter-spacing: 0.1em;
        }

        &_name {
            margin-top: 13px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.1em;
        }
    }
}

@include media(tb) {
    .staff {
        &_title {
            padding-top: 65px;
        }

        &_container {
            flex-direction: column;

            &_item {
                margin-top: 60px;

                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }

        &_text {
            margin-top: 60px;
            padding: 0 20px 65px;
            text-align: justify;
            line-height: $line_height_sp;
        }
    }
}

/*======================
        salonの設定
========================*/

.salon {
    padding-bottom: 110px;

    &_title {
        padding-top: 72px;
        text-align: center;
    }

    &_container {

        &_img,
        &_itemlist {
            margin: 77px auto 0;
            width: 50%;
        }

        &_img {
            padding-right: 62px;
            text-align: center;

            img {
                max-width: 338px;
            }
        }
    }
}

.salon_container_itemlist {
    &_item {
        margin: 0 0 37px;

        a {
            display: inline-block;
            text-decoration: underline;

            &.tel_num {
                text-decoration: none;

                @include media(pc) {
                    pointer-events: none;
                }
            }
        }

        &_title {
            letter-spacing: 0.05em;
            font-size: $fontsize-base;
            width: 70px;
            flex-shrink: 0;

            &-leftspc {
                display: inline-block;
                padding-left: 6px;
            }
        }

        &_text {
            margin-left: 20px;
            margin-right: auto;
            letter-spacing: 0.1em;

            p:not(:first-of-type) {
                line-height: 28px;
                margin-top: 3.5px;
            }

            &-gray {
                color: #acacac;
            }

            &-mt6 {
                margin-top: -2.5px !important; //やむなしimportant
            }
        }
    }
}

@include media(tb) {
    .salon {
        padding-bottom: 65px;

        &_container {
            flex-direction: column;

            &_img,
            &_itemlist {
                width: 100%;
                margin: 60px auto 0;
            }

            &_img {
                padding-right: 0;
            }

            &_itemlist {
                padding: 0 20px;
            }
        }

        &_title {
            padding-top: 65px;
        }
    }

    .salon_container_itemlist {
        &_item {
            justify-content: space-between;
            max-width: 358px;
            margin: 0 auto 37px;

            &:nth-last-child(1) {
                margin: 0 auto;
            }

            &_text {

                p:not(:first-of-type) {
                    line-height: $line_height_sp;
                }
            }
        }


    }
}

/* INSTAGRAM
----------------------------------- */
.insta {
    background: $color-bg;
    text-align: center;
    padding: 91px 0 93px;

    .insta_logo_img {
        width: 28.15px;
        margin: 23px auto 81.59px;
    }
}

@include media(tb) {
    .insta {
        padding: 65px 0;

        &_logo_img {
            margin: 20px auto 60px;
        }

        &_container {
            padding-left: 20px;
            padding-right: 20px;
        }
    }


}