footer {
    height: 146px;
    background: $color-sub;
    font-size: 12px;
    color: #fff;
    padding-top: 44px;

    .flex {
        justify-content: space-between;
    }
}

.footer_content {
    width: 266px;

    @media screen and (max-width: 1023px) {
        width: 32%;
    }

    @include media(tb) {
        width: 100%;
    }
}

.footer_txt {
    vertical-align: text-top;
    letter-spacing: 0.1em;
    line-height: 1.7;

    &.footer_txt1 {
        margin-top: -6px;
    }

    a {
        letter-spacing: 0.1em;
        display: inline;
        color: #fff;
        border-bottom: 1px solid #fff;

        .text_indent {
            padding-left: 3.6px;
        }
    }
}

.footer_btn {
    width: 33.5%;

    .btn_50 {
        max-width: 267.6px;
        margin-top: -1.2px;
        letter-spacing: 0.15em;
        border: 1px solid #fff;
        color: #fff;
        margin-right: 0;
        margin-left: auto;

        &:hover {
            background: #fff;
            color: $color-sub;
        }
    }
}

//tb
@include media(tb) {
    footer {
        height: 302px;
        padding-top: 58px;
        padding-bottom: 21px;

        .flex {
            flex-direction: column;
        }

        .wrap {
            max-width: 375px;
            padding-left: 5.5%;
            padding-right: 5.5%;
        }
    }

    .footer_txt {
        padding-left: 32px;
    }

    .eyerad_footer_content {
        padding-left: 0;
        padding-top: 26.6px;
        margin-bottom: 40px;
    }

    .footer_btn {
        width: 100%;

        .btn_50 {
            max-width: 335px;
            margin-right: auto;
        }
    }
}