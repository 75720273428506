.toppage {
    height: 100vh;
    text-align: center;

    &_container {
        width: 50%;
        background-repeat: no-repeat;
        background-position: center bottom;
        position: relative;

        &_position {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50.5%);
            -webkit-transform: translate(-50%, -50.5%);
        }
    }

    .lrad {
        background-color: $color-main;
        background-image: url(#{$IMG_PATH}lrad_logo_wh2.png);
        background-size: 79.6px;
        color: #fff;

        .toppage_ttl {
            img {
                width: 96.3px;
            }

        }

        .toppage_btn {
            border: 1px solid #fff;

            &:hover {
                background: #fff;
                color: $color-main;
            }
        }
    }

    .eyerad {
        background-image: url(#{$IMG_PATH}eyerad_logo2.png);
        background-size: 128.6px;
        color: $color-main;

        .toppage_ttl {
            img {
                width: 155.5px;
            }

        }

        .toppage_btn {
            border: 1px solid $color-main;

            &:hover {
                background: $color-main;
                color: #fff;
            }
        }
    }

    &_ttl {
        margin-bottom: 93px;

        &_sub {
            font-family: $font-enmain;
            font-weight: 600;
            display: block;
            letter-spacing: 0.15em;
        }

        img {
            margin-bottom: 20px;
        }
    }

    &_img {
        margin: 0 auto 97px;
        width: 73.4%;
    }

    &_btn {
        width: 180px;
        margin: 0 auto 40px;
        font-size: 16px;
        font-family: $font-ensub;
        font-weight: 300;
        letter-spacing: 0.1em;
    }

    &_txt {
        font-size: 12px;
        font-weight: normal;
        font-family: $font-enmain;
        letter-spacing: 0.08em;

        &+.toppage_txt {
            margin-top: 6px;
        }
    }
}



//tb
@include media(tb) {
    .toppage {
        height: auto;

        &_container {
            padding-bottom: 84.5px;

            &_position {
                position: static;
                left: inherit;
                transform: translateX(0);
                -webkit-transform: translateX(-0);
                top: inherit;
            }
        }

        .lrad {
            background-size: 43.8%;

            .toppage_ttl {
                img {
                    width: 43.8%;
                }

            }
        }

        .eyerad {
            background-size: 70.8%;

            .toppage_ttl {
                img {
                    width: 70.8%;
                }
            }
        }

        &_ttl {
            margin-top: 91px;
            margin-bottom: 73px;

            &_sub {
                font-size: 3.2vw;
            }

            img {
                margin-bottom: 18px;
            }
        }

        &_img {
            margin: 0 auto 85px;
            width: 74.6%;
        }

        &_btn {
            width: 140px;
            margin: 0 auto 33px;
            font-size: 14px;
        }

        &_txt {
            letter-spacing: 0.08em;

            &+.toppage_txt {
                margin-top: 6px;
            }
        }
    }
}