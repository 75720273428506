@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*! =======================================
このCSSファイルはSassから生成されていますので、
編集しないようにご注意ください。
========================================= */
/*!
メインの設定(全体)
------------------------------- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  width: 100%;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 300;
  line-height: 1;
  color: #666666;
}

@media screen and (max-width: 768px) {
  body {
    line-height: 1.8;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #666666;
  display: block;
  transition: all 0.3s ease;
}

a:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
  width: 100%;
  vertical-align: bottom;
}

.infopage_area img {
  width: 100%;
  height: auto;
}

p {
  line-height: 1;
}

@media screen and (max-width: 768px) {
  p {
    line-height: 1.8;
  }
}

.pc-none {
  display: none;
}

@media screen and (max-width: 768px) {
  .pc-none {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .sp-none {
    display: none;
  }
}

.wrap {
  max-width: 800px;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .wrap {
    width: 100%;
  }
}

.box_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.btn_50 {
  line-height: 48px;
  text-align: center;
  color: inherit;
}

.btn_50:hover {
  opacity: 1;
}

.btn_70 {
  line-height: 69px;
  text-align: center;
  color: inherit;
}

.box_ttl {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 26px;
  color: #E50012;
  font-weight: 600;
  letter-spacing: 0.3em;
}

/*======================
    MENUの設定
========================*/
#menu {
  background-color: #EAEAEA;
}

.menu {
  padding-bottom: 138px;
}

.menu_title {
  text-align: center;
  padding-top: 100px;
}

.menu_container_img, .menu_container_itemlist {
  width: 50%;
  margin-top: 75px;
}

.menu_container_img {
  padding-right: 20px;
}

.menu_container_img img {
  width: 338px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .menu {
    padding-bottom: 65px;
  }
  .menu_title {
    padding-top: 65px;
  }
  .menu_container {
    flex-direction: column;
    align-items: center;
  }
  .menu_container_img, .menu_container_itemlist {
    width: 100%;
    margin-top: 60px;
  }
  .menu_container_img {
    padding-right: 0;
    text-align: center;
  }
}

/*======================
    WEB予約の設定
========================*/
.web-reservation {
  background-color: #E50012;
  height: 180px;
  padding: 55px 20px;
}

.web-reservation a {
  max-width: 388px;
  height: 70px;
  font-size: 14px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  letter-spacing: 0.15em;
}

.web-reservation a:hover {
  opacity: 1;
  background: #fff;
  color: #E50012;
}

/*======================
        staffの設定
========================*/
.staff_title {
  display: block;
  padding-top: 100px;
  text-align: center;
}

.staff_container {
  justify-content: center;
}

.staff_container_item {
  margin-top: 84px;
}

.staff_container_item:not(:last-child) {
  margin-right: 10px;
}

.staff_container_item_img {
  margin: 0 auto;
  max-width: 260px;
}

.staff_text {
  margin-top: 67px;
  padding-bottom: 109px;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.1em;
}

.staff_container_item_container {
  font-family: "Archivo Narrow", sans-serif;
  text-align: center;
}

.staff_container_item_container_about {
  font-size: 14px;
  margin-top: 32px;
  letter-spacing: 0.1em;
}

.staff_container_item_container_name {
  margin-top: 13px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .staff_title {
    padding-top: 65px;
  }
  .staff_container {
    flex-direction: column;
  }
  .staff_container_item {
    margin-top: 60px;
  }
  .staff_container_item:not(:last-child) {
    margin-right: 0;
  }
  .staff_text {
    margin-top: 60px;
    padding: 0 20px 65px;
    text-align: justify;
    line-height: 1.8;
  }
}

/*======================
        salonの設定
========================*/
.salon {
  padding-bottom: 110px;
}

.salon_title {
  padding-top: 72px;
  text-align: center;
}

.salon_container_img, .salon_container_itemlist {
  margin: 77px auto 0;
  width: 50%;
}

.salon_container_img {
  padding-right: 62px;
  text-align: center;
}

.salon_container_img img {
  max-width: 338px;
}

.salon_container_itemlist_item {
  margin: 0 0 37px;
}

.salon_container_itemlist_item a {
  display: inline-block;
  text-decoration: underline;
}

.salon_container_itemlist_item a.tel_num {
  text-decoration: none;
}

@media screen and (min-width: 769px) {
  .salon_container_itemlist_item a.tel_num {
    pointer-events: none;
  }
}

.salon_container_itemlist_item_title {
  letter-spacing: 0.05em;
  font-size: 14px;
  width: 70px;
  flex-shrink: 0;
}

.salon_container_itemlist_item_title-leftspc {
  display: inline-block;
  padding-left: 6px;
}

.salon_container_itemlist_item_text {
  margin-left: 20px;
  margin-right: auto;
  letter-spacing: 0.1em;
}

.salon_container_itemlist_item_text p:not(:first-of-type) {
  line-height: 28px;
  margin-top: 3.5px;
}

.salon_container_itemlist_item_text-gray {
  color: #acacac;
}

.salon_container_itemlist_item_text-mt6 {
  margin-top: -2.5px !important;
}

@media screen and (max-width: 768px) {
  .salon {
    padding-bottom: 65px;
  }
  .salon_container {
    flex-direction: column;
  }
  .salon_container_img, .salon_container_itemlist {
    width: 100%;
    margin: 60px auto 0;
  }
  .salon_container_img {
    padding-right: 0;
  }
  .salon_container_itemlist {
    padding: 0 20px;
  }
  .salon_title {
    padding-top: 65px;
  }
  .salon_container_itemlist_item {
    justify-content: space-between;
    max-width: 358px;
    margin: 0 auto 37px;
  }
  .salon_container_itemlist_item:nth-last-child(1) {
    margin: 0 auto;
  }
  .salon_container_itemlist_item_text p:not(:first-of-type) {
    line-height: 1.8;
  }
}

/* INSTAGRAM
----------------------------------- */
.insta {
  background: #EAEAEA;
  text-align: center;
  padding: 91px 0 93px;
}

.insta .insta_logo_img {
  width: 28.15px;
  margin: 23px auto 81.59px;
}

@media screen and (max-width: 768px) {
  .insta {
    padding: 65px 0;
  }
  .insta_logo_img {
    margin: 20px auto 60px;
  }
  .insta_container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

footer {
  height: 146px;
  background: #C7B098;
  font-size: 12px;
  color: #fff;
  padding-top: 44px;
}

footer .flex {
  justify-content: space-between;
}

.footer_content {
  width: 266px;
}

@media screen and (max-width: 1023px) {
  .footer_content {
    width: 32%;
  }
}

@media screen and (max-width: 768px) {
  .footer_content {
    width: 100%;
  }
}

.footer_txt {
  vertical-align: text-top;
  letter-spacing: 0.1em;
  line-height: 1.7;
}

.footer_txt.footer_txt1 {
  margin-top: -6px;
}

.footer_txt a {
  letter-spacing: 0.1em;
  display: inline;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.footer_txt a .text_indent {
  padding-left: 3.6px;
}

.footer_btn {
  width: 33.5%;
}

.footer_btn .btn_50 {
  max-width: 267.6px;
  margin-top: -1.2px;
  letter-spacing: 0.15em;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 0;
  margin-left: auto;
}

.footer_btn .btn_50:hover {
  background: #fff;
  color: #C7B098;
}

@media screen and (max-width: 768px) {
  footer {
    height: 302px;
    padding-top: 58px;
    padding-bottom: 21px;
  }
  footer .flex {
    flex-direction: column;
  }
  footer .wrap {
    max-width: 375px;
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
  .footer_txt {
    padding-left: 32px;
  }
  .eyerad_footer_content {
    padding-left: 0;
    padding-top: 26.6px;
    margin-bottom: 40px;
  }
  .footer_btn {
    width: 100%;
  }
  .footer_btn .btn_50 {
    max-width: 335px;
    margin-right: auto;
  }
}

.toppage {
  height: 100vh;
  text-align: center;
}

.toppage_container {
  width: 50%;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
}

.toppage_container_position {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50.5%);
  -webkit-transform: translate(-50%, -50.5%);
}

.toppage .lrad {
  background-color: #E50012;
  background-image: url(../img/lrad_logo_wh2.png);
  background-size: 79.6px;
  color: #fff;
}

.toppage .lrad .toppage_ttl img {
  width: 96.3px;
}

.toppage .lrad .toppage_btn {
  border: 1px solid #fff;
}

.toppage .lrad .toppage_btn:hover {
  background: #fff;
  color: #E50012;
}

.toppage .eyerad {
  background-image: url(../img/eyerad_logo2.png);
  background-size: 128.6px;
  color: #E50012;
}

.toppage .eyerad .toppage_ttl img {
  width: 155.5px;
}

.toppage .eyerad .toppage_btn {
  border: 1px solid #E50012;
}

.toppage .eyerad .toppage_btn:hover {
  background: #E50012;
  color: #fff;
}

.toppage_ttl {
  margin-bottom: 93px;
}

.toppage_ttl_sub {
  font-family: "Archivo Narrow", sans-serif;
  font-weight: 600;
  display: block;
  letter-spacing: 0.15em;
}

.toppage_ttl img {
  margin-bottom: 20px;
}

.toppage_img {
  margin: 0 auto 97px;
  width: 73.4%;
}

.toppage_btn {
  width: 180px;
  margin: 0 auto 40px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.toppage_txt {
  font-size: 12px;
  font-weight: normal;
  font-family: "Archivo Narrow", sans-serif;
  letter-spacing: 0.08em;
}

.toppage_txt + .toppage_txt {
  margin-top: 6px;
}

@media screen and (max-width: 768px) {
  .toppage {
    height: auto;
  }
  .toppage_container {
    padding-bottom: 84.5px;
  }
  .toppage_container_position {
    position: static;
    left: inherit;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    top: inherit;
  }
  .toppage .lrad {
    background-size: 43.8%;
  }
  .toppage .lrad .toppage_ttl img {
    width: 43.8%;
  }
  .toppage .eyerad {
    background-size: 70.8%;
  }
  .toppage .eyerad .toppage_ttl img {
    width: 70.8%;
  }
  .toppage_ttl {
    margin-top: 91px;
    margin-bottom: 73px;
  }
  .toppage_ttl_sub {
    font-size: 3.2vw;
  }
  .toppage_ttl img {
    margin-bottom: 18px;
  }
  .toppage_img {
    margin: 0 auto 85px;
    width: 74.6%;
  }
  .toppage_btn {
    width: 140px;
    margin: 0 auto 33px;
    font-size: 14px;
  }
  .toppage_txt {
    letter-spacing: 0.08em;
  }
  .toppage_txt + .toppage_txt {
    margin-top: 6px;
  }
}

.comhead {
  background: #C7B098;
  height: 90px;
  font-size: 10px;
  letter-spacing: 0.05em;
  font-family: "Archivo Narrow", sans-serif;
  color: #fff;
  margin-bottom: 109px;
}

.comhead_container {
  height: 33.2px;
  align-items: flex-end;
}

.comhead_container section h1 {
  flex-shrink: 0;
}

.comhead_container section div {
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 17px;
}

.comhead_container section div p:nth-last-child(1) {
  margin-top: 4px;
}

.comhead_container_item1 h1 {
  width: 79.685px;
}

.comhead_container_item2 {
  margin-left: 70px;
}

.comhead_container_item2 h1 {
  width: 128.642px;
}

.company {
  margin-bottom: 190px;
}

.company .box_ttl {
  text-align: center;
  margin-bottom: 120px;
}

#section-compage .container_title {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.1em;
  margin-bottom: 38px;
  font-weight: 600;
}

#section-compage .container_item {
  border-top: 1px solid #C4C4C4;
}

#section-compage .container_item_ttl {
  font-size: 12px;
  color: #ACACAC;
  padding-left: 51px;
  padding-top: 30px;
  padding-bottom: 29px;
  width: 174px;
  letter-spacing: 0.1em;
}

#section-compage .container_item_txt {
  padding-top: 29px;
  padding-bottom: 28px;
  letter-spacing: 0.1em;
  width: calc(100% - 174px);
}

#section-compage .container_item_txt_line {
  margin-bottom: 22.5px;
}

#section-compage .container_item_txt_line_ko {
  padding-left: 2.1em;
}

#section-compage .compage_container .container_item:nth-last-child(1) {
  border-bottom: 1px solid #C4C4C4;
}

.recruit {
  margin-bottom: 118px;
}

.recruit .box_ttl {
  text-align: center;
  margin-bottom: 71px;
}

.recruit_text {
  margin-bottom: 120px;
  line-height: 2.28;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .comhead {
    height: 60px;
    margin-bottom: 63px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .comhead_container {
    height: 33.2px;
    align-items: flex-end;
  }
  .comhead_container section div {
    display: none;
  }
  .comhead_container_item2 {
    margin-left: 40px;
  }
  .company {
    margin-bottom: 120px;
  }
  .company .box_ttl {
    margin-bottom: 45px;
  }
  #section-compage .container_title {
    margin-bottom: 30px;
  }
  #section-compage .container_item_ttl {
    padding-left: 20px;
    padding-right: 20px;
    width: calc(40px + 20%);
    padding-top: 30px;
    padding-bottom: 29px;
  }
  #section-compage .container_item_txt {
    width: calc(80% - 40px);
    padding-right: 20px;
    padding-top: 29px;
    padding-bottom: 28px;
  }
  #section-compage .container_item_txt_line {
    margin-bottom: 7px;
  }
  #section-compage .container_item_txt_line_sp {
    display: block;
    margin-bottom: 7px;
  }
  #section-compage .container_item_txt_line_ko {
    padding-left: 2.1em;
  }
  .recruit {
    margin-bottom: 60px;
  }
  .recruit .box_ttl {
    margin-bottom: 45px;
  }
  .recruit_text {
    margin-bottom: 60px;
    line-height: 1.8;
  }
  #section-compage {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 480px) {
  #section-compage .flex {
    flex-direction: column;
  }
  #section-compage .container_item_ttl {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  #section-compage .container_item_txt {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1em;
    padding-bottom: 20px;
  }
  #section-compage .container_item_txt_line {
    margin-bottom: 7px;
  }
  #section-compage .container_item_txt_line_sp {
    display: block;
    margin-bottom: 7px;
  }
  #section-compage .container_item_txt_line_ko {
    padding-left: 2.1em;
  }
}
