// ----------------------------------
// サイトの基本設定
// ------------------------------------

//幅関係の設定
$width-base: 800px; //全体の幅



//フォントの設定
$font-base: "ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
"ＭＳ Ｐゴシック",
sans-serif;
$font-enmain: 'Archivo Narrow',
sans-serif;
$font-ensub: 'Poppins',
sans-serif;

//色関係の設定
//フォントカラー
$color-font: #666666;
//サイトカラー
$color-main: #E50012;
$color-bg: #EAEAEA;
$color-sub: #C7B098;

//font-size
$fontsize-base: 14px;

//borderの設定
$border-gray: 1px solid #C4C4C4;

//行間
$line_height: 1;
$line_height_sp: 1.8;

//IMG_PATH
$IMG_PATH: '../img/';

//ブレイクポイント
$breakpoints: (sp:"screen and (max-width:480px)", tb:"screen and (max-width:768px)",
pc:"screen and (min-width:769px)",
);

@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//疑似クラス用
@mixin gizi {
    content: '';
    display: block;
}

//文字の均等配置
@mixin kintou {
    -moz-text-align-lastc: justify;
    text-align-last: justify;
    text-justify: inter-ideograph;
}

//真ん中中央
@mixin mannaka {
    display: flex;
    justify-content: center;
    align-items: center;
}

//clearfix
@mixin clearfix {
    content: '';
    display: block;
    clear: both;
}