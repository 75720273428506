.comhead {
  background: $color-sub;
  height: 90px;
  font-size: 10px;
  letter-spacing: 0.05em;
  font-family: $font-enmain;
  color: #fff;
  margin-bottom: 109px;

  &_container {
    height: 33.2px;
    align-items: flex-end;
  }
}

.comhead_container section {
  h1 {
    flex-shrink: 0;
  }

  div {
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 17px;

    p:nth-last-child(1) {
      margin-top: 4px;
    }
  }
}

.comhead_container_item1 {
  h1 {
    width: 79.685px;
  }
}

.comhead_container_item2 {
  margin-left: 70px;

  h1 {
    width: 128.642px;
  }
}

.company {
  margin-bottom: 190px;

  .box_ttl {
    text-align: center;
    margin-bottom: 120px;
  }
}

#section-compage {
  .container_title {
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.1em;
    margin-bottom: 38px;
    font-weight: 600;
  }

  .container_item {
    border-top: $border-gray;

    &_ttl {
      font-size: 12px;
      color: #ACACAC;
      padding-left: 51px;
      padding-top: 30px;
      padding-bottom: 29px;
      width: 174px;
      letter-spacing: 0.1em;
    }

    &_txt {
      padding-top: 29px;
      padding-bottom: 28px;
      letter-spacing: 0.1em;
      width: calc(100% - 174px);

      &_line {
        margin-bottom: 22.5px;

        &_ko {
          padding-left: 2.1em;
        }
      }
    }
  }

  .compage_container {
    .container_item:nth-last-child(1) {
      border-bottom: $border-gray;
    }
  }
}

.recruit {
  margin-bottom: 118px;

  .box_ttl {
    text-align: center;
    margin-bottom: 71px;
  }

  &_text {
    margin-bottom: 120px;
    line-height: 2.28;
    letter-spacing: 0.1em;
  }
}

@include media(tb) {
  .comhead {
    height: 60px;
    margin-bottom: 63px;
    padding-right: 20px;
    padding-left: 20px;

    &_container {
      height: 33.2px;
      align-items: flex-end;
    }
  }

  .comhead_container section {
    div {
      display: none;
    }
  }

  .comhead_container_item2 {
    margin-left: 40px;
  }

  .company {
    margin-bottom: 120px;

    .box_ttl {
      margin-bottom: 45px;
    }
  }

  #section-compage {
    .container_title {
      margin-bottom: 30px;
    }

    .container_item {
      &_ttl {
        padding-left: 20px;
        padding-right: 20px;
        width: calc(40px + 20%);
        padding-top: 30px;
        padding-bottom: 29px;
      }

      &_txt {
        width: calc(80% - 40px);
        padding-right: 20px;
        padding-top: 29px;
        padding-bottom: 28px;

        &_line {
          margin-bottom: 7px;

          &_sp {
            display: block;
            margin-bottom: 7px;
          }

          &_ko {
            padding-left: 2.1em;
          }
        }
      }
    }
  }

  .recruit {
    margin-bottom: 60px;

    .box_ttl {
      margin-bottom: 45px;
    }

    &_text {
      margin-bottom: 60px;
      line-height: $line_height_sp;
    }
  }

  #section-compage {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@include media(sp) {
  #section-compage {
    .flex {
      flex-direction: column;
    }

    .container_item {
      &_ttl {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
      }

      &_txt {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 1em;
        padding-bottom: 20px;

        &_line {
          margin-bottom: 7px;

          &_sp {
            display: block;
            margin-bottom: 7px;
          }

          &_ko {
            padding-left: 2.1em;
          }
        }
      }
    }
  }
}